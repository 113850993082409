import _ from 'lodash';

const refinementKeyValueLabelMap = {
  'categoryLevels.level_0': {
    keyLabel: 'Category'
  },
  'productPartners.partner.legalName': {
    keyLabel: 'Partners'
  },
  'price.sortPrice': {
    keyLabel: 'Price Range'
  },
  isDiscountAvailable: {
    keyLabel: 'Discount Available',
    getValueLabel: () => 'Yes'
  },
  'productOrderStatistics.userEventCount': {
    keyLabel: 'Event Count'
  }
};

const transformCurrentRefinementItemsLabel = (items) =>
  items.map((item) => {
    const {
      keyLabel = _.startCase(item.label),
      getValueLabel = (refinement) => refinement.label
    } = refinementKeyValueLabelMap[item.attribute] || {};

    const refinements = item.refinements.map((refinement) => ({
      ...refinement,
      label: getValueLabel(refinement)
    }));

    return { ...item, label: keyLabel, refinements };
  });

export default transformCurrentRefinementItemsLabel;

export const getMenuFromCurrentURL = ({
  attribute,
  index,
  instantSearchRouter,
  setSelectedTab
}) => {
  const urlParams = new URLSearchParams(
    instantSearchRouter.getLocation().search
  );
  const menu = urlParams.get(`${index}[menu][${attribute}]`);
  setSelectedTab(menu);
};

export const getCountForAttribute = ({ attribute, currentRefinements }) => {
  const { refinements: attributeRefinements = [] } =
    currentRefinements.find(
      (refinement) => refinement.attribute === attribute
    ) || {};
  return attributeRefinements.length;
};
